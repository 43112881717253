import { Button, Container, Grid, useMediaQuery } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { getToken } from "middleware/storage";
import YoozAccordion from "components/public-dashboard/components/yoozAccordion";
import { setTabPosition } from "components/public-dashboard/uiSlice";
import videoFile from "assets/videos/Failed-Transactions-Why-&-What-to-do.mp4";
import scan2payemail from "assets/images/Picture1.png";
import scan2payreciept from "assets/images/Picture2.png";
import {API_ROOT} from "constants/actionTypes";

const useStyles = makeStyles((theme) => ({
  paper: {
    flexGrow: 1,
    width: "100%",
    marginTop: theme.spacing(5),
  },
  img: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "75%",
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
  video: {
    width: "50%",
  },
}));

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function FAQContent() {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  let accCount = 0;

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const token = getToken();

  return (
    <Container>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="secondary"
        variant={sm ? "scrollable" : "standard"}
        scrollButtons="on"
        centered={sm ? false : true}
      >
        <Tab
          label={<Typography variant={md ? "body1" : "h6"}>GENERAL</Typography>}
          {...a11yProps(0)}
        />
        <Tab
          label={
            <Typography variant={md ? "body1" : "h6"}>Pay Bills </Typography>
          }
          {...a11yProps(1)}
        />
        <Tab
          label={<Typography variant={md ? "body1" : "h6"}>Top Up </Typography>}
          {...a11yProps(2)}
        />
        <Tab
          label={<Typography variant={md ? "body1" : "h6"}>Sign Up</Typography>}
          {...a11yProps(3)}
        />
        <Tab
          label={
            <Typography variant={md ? "body1" : "h6"}>Security </Typography>
          }
          {...a11yProps(4)}
        />
        <Tab
          label={
            <Typography variant={md ? "body1" : "h6"}>Yooz Business</Typography>
          }
          {...a11yProps(5)}
        />
        <Tab
          label={
            <Typography variant={md ? "body1" : "h6"}>Yooz Agent</Typography>
          }
          {...a11yProps(6)}
        />
      </Tabs>
      <Paper className={classes.paper}>
        <TabPanel value={value} index={0}>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="What is YOOZ? "
          >
            If you ask our customers and partners, they’d say it’s the best
            digital payment service in T&T! <br />
            <br />
            <b>YOOZ</b> is actually the most comprehensive <b>digital</b>{" "}
            payment service that lets you Top Up, Pay Bills and Collect Payments
            Online, and at Authorized Agent locations.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="WHAT SERVICES CAN I ACCESS ON YOOZ? "
          >
            You can Top Up and Pay Bills on YOOZ Online or at a YOOZ Agent.
            <br />
            <br />
            Top Up your mobile phone or someone else’s on any network and Pay
            Bills and invoices for a variety of utilities and vendors.
            <br />
            <br />
            <b>YOOZ Online</b> <br />
            You can Top Up & Pay Bills using the Quick Pay option, no sign-up
            necessary. Or you can sign up FREE and create a secure Login Account
            where you can make payments, save card details, and keep track of
            all your payments and account info, making payments easier and
            faster.
            <br />
            <br />
            <b>YOOZ Agents</b> <br />
            There are over 1,000 authorised YOOZ Agents located throughout Trinidad and Tobago. Look out for the YOOZ Authorised
            Agent sticker at your nearby shop, supermarket, or pharmacy within
            your community.
            <br />
            <br />
            We’re always adding new utilities and merchants, so stay tuned!
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="Are there any fees for using YOOZ? "
          >
            <b>YOOZ Online</b> is FREE to Top Up & Pay Bills!
            <br />
            There are no additional fees when you Top Up at YOOZ Agents.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="DOES YOOZ ONLINE WORK ON ALL MOBILE PHONES? "
          >
            Yes, <b>YOOZ</b> works on all mobile phones.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="IS THERE A YOOZ APP I CAN DOWNLOAD? "
          >
            <b>YOOZ</b> does not currently have an app to be downloaded on the
            Google Android or iOS App stores.
            <br />
            <br />
            To have quick access to our <b>YOOZ</b> Online service on your
            mobile phone, follow the steps below (based on your device). This
            will save the <b>YOOZ</b> website as an app icon on your phone.
            Whenever you need to Pay a Bill or Top Up, simply click on it and
            proceed to our Quick Pay or Login tabs on our website.
            <br />
            <br />
            Android:
            <ul>
              <li>
                Visit our site:{" "}
                <a href="https://portal.yoozit.today" target="blank">
                  https://portal.yoozit.today/ {" "}
                </a>{" "}
              </li>
              <li>Click on the 3 vertical buttons on the right of browser.</li>
              <li>A drop-down menu will appear.</li>
              <li>Click on “Install App”.</li>
              <li>Click “Install”.</li>
            </ul>
            <br />
            iPhone:
            <ul>
              <li>
                Visit our site:{" "}
                <a href="https://portal.yoozit.today" target="blank">
                  https://portal.yoozit.today/ {" "}
                </a>{" "}
              </li>
              <li>Click on the Share button at bottom of screen.</li>
              <li>Click on “Add to Home Screen”. </li>
            </ul>
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="WHAT HAPPENS IF I GET A NEW MOBILE NUMBER? "
          >
            Your mobile number is linked to your registered <b>YOOZ</b> Online
            account. If you registered using your old mobile number, then you need to contact us via
            yooz@yoozit.today or call us at +1 (868) 235-6182, to change your number on
            your Login account
            <br />
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="ARE INTERNATIONAL DEBIT AND CREDIT CARDS ACCEPTED ON THE PLATFORM? "
          >
            Yes. Presently any Visa or MasterCard can be used to transact on{" "}
            <b>YOOZ</b> Online.
            <br />
            <br />
            <b>YOOZ</b> Agents only accept Cash and Visa Debit Card payments.
            <br />
          </YoozAccordion>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="WHAT IS YOOZ BILL PAY? "
          >
            <b>YOOZ</b>  Bill Pay gives customers the convenience to Pay Bills
            Online (YOOZ Online) or at over 150+ Authorised YOOZ Agents located
            throughout Trinidad and Tobago.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="HOW DO I PAY BILLS WITH YOOZ ONLINE? "
          >
            <Button
              onClick={() => {
                window.scrollTo(0, 0);
                dispatch(setTabPosition(7));
              }}
              component={RouterLink}
              to={token ? "/user/billpay" : "/quickpay"}
              color="primary"
              size="small"
              variant="contained"
            >
              Quick Pay
            </Button>
            <br />
            <br />
            No need to register! Just follow the steps to enter your payment
            information and you’re done!
            <br /> <br />
            <Button
              onClick={() => {
                window.scrollTo(0, 0);
                dispatch(setTabPosition(7));
              }}
              component={RouterLink}
              to="/login"
              color="primary"
              size="small"
              variant="contained"
            >
              LOG IN PROFILE
            </Button>
            <br />
            <br />
            Click LOGIN found on top menu bar. If not already a registered user,
            head to{" "}
            <a href="SIGNUP" target="blank">
              SIGNUP
            </a>{" "}
            page and create a profile. Once logged in click on Bill Pay tab,
            enter payment details and click submit. This option allows you to
            track all payments made on YOOZ Online. You can also save your card
            details, making it easier and faster to use for future transactions.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="HOW DO I PAY BILLS AT A YOOZ AGENT? "
          >
            Look for <b>YOOZ</b> branding to identify Authorised <b>YOOZ</b>{" "}
            Agents. Provide the account number for the Service Provider you want
            to pay. You will have the option of paying in cash or with a debit
            card (depending on the Agent). Once the transaction is successful
            you will receive a printed receipt.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="IS THERE A MINIMUM OR MAXIMUM AMOUNT I CAN PAY ON A BILL? "
          >
            <b>YOOZ Online</b> <br />
            Minimum payment per transaction - $25
            <br />
            Maximum payment per transaction - $5,000 per Scan2Pay merchant{" "}
            <br />
            Maximum payment per transaction - $20,000 per utility/service
            provider <br />
            <i>*Exceptions may apply based on the Service Provider</i> <br />
            <br />
            <br />
            <b>YOOZ Agent</b> <br />
            Minimum payment per transaction - $5 <br />
            Maximum payment per transaction - $5,000 <br />
            <i>*Exceptions may apply based on the Service Provider</i> <br />
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="WHEN WILL THE PAYMENT BE APPLIED TO MY ACCOUNT?  "
          >
            The payment will be applied to your account within one working day
            or less depending on the Payee (Utility or Service Provider). You
            can contact your service provider to confirm.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="CAN I PAY SOMEONE ELSE’S BILL? "
          >
            Yes!
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="WHAT IF I ACCIDENTALLY PAID THE WRONG BILL OR ENTERED THE WRONG ACCOUNT NUMBER? "
          >
            Contact the Payee (Utility or Service Provider) as soon as possible
            and they will be able to assist you.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="HOW WILL I KNOW IF MY TRANSACTION WAS SUCCESSFUL? "
          >
            <b>YOOZ Online</b> <br />
            You will receive a <b>Successful</b> Transaction receipt via email
            for Online transactions.
            <br />
            <br />
            <b>YOOZ Agent</b>
            <br />
            You will receive a printed receipt for Agent transactions.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="IF A TRANSACTION FAILS, CAN I TRY AGAIN?  "
          >
            <b>YOOZ Online</b>
            <br />
            <br />
            Yes. Check to ensure you entered the correct card number, CVV number
            and that you have funds on your card, then try again.
            <br />
            <br />
            <i>You can refer to our SECURITY section for more on this.</i>
          </YoozAccordion>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="WHAT IS YOOZ TOP UP?"
          >
            <b>YOOZ</b> Top Up lets prepaid mobile phone customers Top Up their
            credit or someone else’s directly from their phone or device.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="CAN I PAY MY POSTPAID BILL BY TOPPING UP?"
          >
            No. You can pay a Digicel postpaid bill via our Bill Pay service on
            YOOZ Online Quick Pay or Login. We presently do not accept bmobile
            postpaid bill payments.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="HOW DO I TOP UP WITH YOOZ ONLINE?"
          >
            <Button
              onClick={() => {
                window.scrollTo(0, 0);
                dispatch(setTabPosition(7));
              }}
              component={RouterLink}
              to="/quicktopup"
              color="primary"
              size="small"
              variant="contained"
            >
              Quick TopUp
            </Button>
            <br />
            <br />
            No need to Sign-up! Just follow the steps to enter your payment
            information and you’re done!
            <br />
            <br />
            <Button
              onClick={() => {
                window.scrollTo(0, 0);
                dispatch(setTabPosition(7));
              }}
              component={RouterLink}
              to="/login"
              color="primary"
              size="small"
              variant="contained"
            >
              LOG IN PROFILE
            </Button>
            <br />
            <br />
            Click LOGIN found on top menu bar. If not already a registered user,
            head to{" "}
            <a href="SIGNUP" target="blank">
              SIGNUP
            </a>{" "}
            page and create a profile. Once logged in click on Top Up tab, enter
            payment details and click submit. This option allows you to track
            all payments made on YOOZ Online. You can also save your card
            details, making it easier and faster to use for future transactions.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="IS THERE A MINIMUM OR MAXIMUM TOP UP THAT I CAN PURCHASE? "
          >
            <b>YOOZ Online</b> <br />
            Minimum Top Up per transaction - $60 <br />
            Maximum Top Up per transaction - $600 <br />
            <br />
            <b>YOOZ Agent</b> <br />
            Minimum Top Up per transaction – Digicel $13.33 | Bmobile $15.00{" "}
            <br />
            Maximum Top Up per transaction – Digicel & Bmobile $511.11 <br />
            <br />
            <i>
              <b>All prices are subject to 12.5% VAT.</b>
            </i>
            <br />
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="WHY IS THE MINIMUM TOP UP AMOUNT $60.00? "
          >
            For every Online transaction there are bank and processing fees
            charged by our bank and the credit card companies.  At <b>YOOZ</b>,
            we absorb these fees so our Customers can purchase Top Up at the vat
            inclusive value <b>at no additional cost</b>.  It is for this reason
            we can only offer Top Up @ a minimum of $60.00 on our Online
            service.  The lower Top Up denominations are available at all our
            Agent locations as these are paid for in cash and are not processed
            Online.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="CAN I TOP UP SOMEONE ELSE’S PHONE?"
          >
            Yes! On <b>YOOZ</b> Online, you can Top Up someone else’s phone on
            any network by simply entering their phone number.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="WHAT IF I ACCIDENTALLY TOPPED UP THE WRONG NUMBER?"
          >
            It is extremely important to check and double check the number you
            want to Top Up before you confirm the purchase. In the event you
            confirm the purchase, and the number is incorrect, the transaction
            can’t be reversed. <br />
            <br />
            In the event you did Top Up the wrong number please contact your
            Carrier (Bmobile or Digicel), inform them of the error and request a
            reversal. We recommend that this be done immediately as the Carrier
            will not be able to reverse the funds if the Top Up is used by the
            number that received it.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="HOW WILL I KNOW IF MY TRANSACTION WAS SUCCESSFUL? "
          >
            <b>YOOZ Online</b> <br />
            You will receive a <b>Successful</b> Transaction receipt via email.
            <br />
            <br />
            <b>YOOZ Agent</b>
            <br />
            Pin Top Ups - you will receive a printed receipt. <br />
            Direct Top Ups - these are confirmed via an SMS message on your
            phone.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="IF A TRANSACTION FAILS, CAN I TRY AGAIN?  "
          >
            <b>YOOZ Online</b>
            <br />
            <br />
            Yes. Check to ensure you entered the correct card number, CVV number
            and that you have funds on your card, then try again.
            <br />
            <br />
            <i>You can refer to our SECURITY section for more on this.</i>
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="I DID A TOP UP BUT I DID NOT RECEIVE IT AND MY BANK ACCOUNT WAS DEBITED. WHAT DO I DO? "
          >
            Our system captures all transactions – successful and failed. In
            cases like above, our system is set up to do an automatic reversal
            and your funds will appear in your bank account within 2 business
            days. If however, your funds are not automatically reversed and
            based on evidence of your account being debited, please contact us
            via phone +1 (868) 235-6182 or email{" "}
            <a
              href="mailto: yooz@yoozit.today"
              className={classes.yoozBlueLink}
            >
              yooz@yoozit.today
            </a>{" "}
            and provide:
            <ul>
              <li>Your YOOZ registered number (if registered) </li>
              <li>
                The phone number you were topping up (if different from your
                registered number){" "}
              </li>
              <li>Transaction amount </li>
              <li>
                Supporting documents showing the deductions on your account.{" "}
              </li>
            </ul>
          </YoozAccordion>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="HOW DO I REGISTER FOR YOOZ Online? "
          >
            <Button
              onClick={() => {
                window.scrollTo(0, 0);
                dispatch(setTabPosition(7));
              }}
              component={RouterLink}
              to="/signup"
              color="primary"
              size="small"
              variant="contained"
            >
              SIGNUP HERE
            </Button>
            <br />
            <br />
            You can click on link above or on SIGNUP tab found on top menu bar.
            <br />
            <br />
            You will need:
            <ul>
              <li>Email Address</li>
              <li>Phone Number</li>
            </ul>
            Just follow the steps and you are good to go.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="IS REGISTRATION FREE?"
          >
            Yes.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="WHAT IS A SIGNUP VERIFICATION? "
          >
            After you fill out the online sign-up form and click SUBMIT, a
            SIGNUP Verification page will open, requesting a 6-digit code One
            Time Password (OTP) that will be sent via SMS to the phone number
            entered on the sign-up form.
            <br />
            <br />
            You must enter that OTP number on the SIGNUP Verification Page to
            gain access to your account.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="HOW DO I KNOW MY SIGNUP IS COMPLETE?  "
          >
            You will gain access to your YOOZ Account Dashboard on entering
            the correct OTP on the SIGNUP Verification page.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="DOES MY LOGIN HAVE 2-FACTOR AUTHENTICATION? "
          >
            Yes. Every time you Login, a LOGIN Verification Page opens,
            requesting a 6-digit code One Time Password (OTP) that will be sent
            via SMS to the registered phone number on your account.
            <br />
            <br />
            You must enter that OTP number on the LOGIN Verification Page to
            gain access to your account.
            <br />
            <br />
            You have 120 seconds to enter the code before it becomes invalid.
            <br />
            <br />
            If you are unable to submit the code in time you can click on RESEND
            CODE on the LOGIN Verification Page, to request another code. .
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="CAN I SAVE MY CARD DETAILS ON MY YOOZ ONLINE ACCOUNT?"
          >
            Yes! The option to save your card details is given after you sign
            up for the service.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="HOW DO I KNOW MY CARD INFORMATION IS SAFE WHEN I SAVE IT ON YOOZ ONLINE? "
          >
            All card data entered, is coded (tokenized) and then shared with
            your bank. Only your bank can de-code. <br /> <br />
            YOOZ has no access to your bank account data.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="DO I HAVE TO SAVE MY CARD DETAILS WHEN I SIGNUP FOR YOOZ ONLINE?"
          >
            No. Saving your card details is optional.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="CAN I SAVE MULTIPLE BANK CARDS TO MY YOOZ ACCOUNT? "
          >
            Yes. For YOOZ Online multiple bank cards can be saved on one
            Account.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="WHEN SAVING MULTIPLE BANK CARDS, WHAT PHONE NUMBERS CAN I SAVE?"
          >
            When saving multiple bank cards you can save phone numbers other
            than your registered number.
            <br />
            <br />
            For each bank card you save, it is recommended that you save the
            phone number that your bank has on file for you.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="WHAT IF MY BANK CARD EXPIRES AND I NEED TO CHANGE IT? "
          >
            For YOOZ Online simply log in to your profile and save the new bank
            card details under Saved Cards found in the Account tab. You can
            also delete the expired card if it was saved previously.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="DO I NEED TO RE-REGISTER ONLINE IF I WANT TO CHANGE / ADD MY BANK CARD DETAILS?"
          >
            No. You log in to your existing account and update / add your new
            card details under Saved Cards found in the Account Tab.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="CAN I REGISTER MULTIPLE PHONE NUMBERS ON ONE YOOZ ACCOUNT?   "
          >
            No. Only 1 phone number can be registered to each YOOZ account. The
            One Time Passcode will be sent to the registered phone number when
            logging in.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="CAN I REGISTER THE SAME PHONE NUMBER or EMAIL UNDER DIFFERENT YOOZ ACCOUNTS?   "
          >
            No. Each phone number and email address entered on SIGNUP is unique
            to each account. A phone number and/or email used for a previously
            successful sign-up will not be accepted and the sign-up will not be
            successful.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="I TRIED TO SIGNUP BUT I GOT AN ERROR MESSAGE THAT AN ACCOUNT ALREADY EXISTS FOR MY PHONE NUMBER / EMAIL ADDRESS. WHAT DO I DO?"
          >
            Email us at{" "}
            <a
              href="mailto: yooz@yoozit.today"
              className={classes.yoozBlueLink}
            >
              yooz@yoozit.today
            </a>
            , providing your name, phone number and email address. We will work
            with you to have you registered and using our service as soon as
            possible.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="I FORGOT MY PASSWORD, WHAT CAN I DO TO LOGIN?  "
          >
            On the Login page, click on Forgot Password. An email will be sent
            to help you reset your password. Simply follow the instructions and
            you will be YOOZing again in no time.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="I FORGOT MY PASSWORD, BUT I AM GETTING AN ERROR MESSAGE THAT THE EMAIL I ENTERED TO RESET MY PASSWORD IS INVALID.  WHAT DO I DO?   "
          >
            Email us at{" "}
            <a
              href="mailto: yooz@yoozit.today"
              className={classes.yoozBlueLink}
            >
              yooz@yoozit.today
            </a>
            , providing your name, phone number and email. We will work with you
            to have you using our service as soon as possible.
            <br />
            <br />
            Remember, the email entered to reset your password must be the same
            as the one entered on sign-up. If you have forgotten that email, no
            problem at all, simply contact us as stated above.
          </YoozAccordion>

          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="HOW DO I UNREGISTER FOR YOOZ? "
          >
            Email us at{" "}
            <a
              href="mailto: yooz@yoozit.today"
              className={classes.yoozBlueLink}
            >
              yooz@yoozit.today
            </a>
            , providing your name, phone number and email. Once we confirm your
            identity, you’ll be unregistered.
          </YoozAccordion>

          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="DO I HAVE TO ENTER A REFERRAL CODE WHEN SIGNING UP FOR YOOZ ONLINE?  "
          >
            No.
            <br />
            <br />
            The referral code is a reference for 2 reasons. Sometimes we run
            registration promotions and to qualify for a prize we give you a
            referral code, which you can enter on sign-up.
            <br />
            <br />
            If you are signing up and did not receive a referral code, just tell
            us where you heard about us. This helps us understand where you
            heard about us, so we can continue to communicate with you there,
            allowing us to share all our latest innovations that will make your
            payments simpler, faster and more secure.
          </YoozAccordion>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="HOW SAFE IS YOOZ? "
          >
            <Grid container>
              <Grid item xs={12}>
                <b>YOOZ</b> is a Payment Service Provider that is 3D Secure 2.0
                Certified and PCI-DSS Compliant (Payment Card Industry Data
                Security Standard).
                <br />
                <br />
                Our system safeguards your transaction data while making the
                payment process smoother and easier for you. It also monitors
                suspicious
                <br />
                transactions online and sends real-time alerts on any suspicious
                or potentially fraudulent transactions.
                {/* <Button
                  onClick={() => {
                    window.scrollTo(0, 0);
                    dispatch(setTabPosition(7));
                  }}
                  component={RouterLink}
                  to="/signup"
                  color="primary"
                  startIcon={<WebIcon />}
                >
                  Online
                </Button> */}
              </Grid>
              {/* <Grid item xs={12}>
                <Button
                  onClick={() => {
                    window.scrollTo(0, 0);
                    dispatch(setTabPosition(7));
                  }}
                  component={RouterLink}
                  to={token ? "/user/contactus" : "/contact"}
                  color="primary"
                  startIcon={<DirectionsRunIcon />}
                >
                  In person. Find the location nearest you.
                </Button>
              </Grid> */}
            </Grid>
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="WHAT IS CVV? "
          >
            CVV or Card Verification Value is a 3-digit number located at the
            back of your credit card or visa debit card, on the right of the
            signature panel.
            <br />
            This number provides added security and providing it when doing
            online transactions verifies that the card is physically available
            with the
            <br />
            individual using it during the transaction.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="WHAT IS 3D SECURE 2? "
          >
            3D Secure 2 is a cardholder authentication mechanism.
            <br />
            <br />
            YOOZ collaborates with your bank to verify that the person using
            your card to make the online purchase, is in fact you.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="HOW DOES 3D SECURE 2 WORK? "
          >
            <ol>
              <li>
                On the YOOZ Payment page‚ you enter the transaction details,
                your bank card information and click submit.{" "}
              </li>
              <li>
                YOOZ through its payment processor First Atlantic Commerce
                (FAC) codes the data and submits it to your bank.
              </li>
              <li>
                Your bank analyses the transaction details along with the
                information you would have given them previously e.g.
                <br />
                email address, phone number, billing address or previous online
                transaction history using the card.
              </li>
              <li>
                If your bank is satisfied that the person making the purchase, is
                you, they Approve the transaction.
              </li>
              <li>
                If the bank is not satisfied, they may send a request, known as
                a Challenge (within the YOOZ payment page) to ask you to verify
                your identity.
              </li>
              <li>
                If you respond correctly your bank approves the transaction.
              </li>
              <li>
                If your bank is not satisfied, or believes they need more
                information from you they will not authorise the transaction.
              </li>
              <li>
                Only when YOOZ gets approval from your bank‚ do we charge your
                card.
              </li>
            </ol>
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="HOW LONG DOES THIS TAKE? "
          >
            Seconds
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="IS MY INFORMATION SHARED BETWEEN YOOZ AND MY BANK SAFE? "
          >
            Yes.
            <br />
            <br />
            All card data entered, is coded (tokenized) and then shared with
            your bank. Only your bank can de-code.
            <br />
            <br />
            YOOZ has no access to your bank account data.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="WHY DOES YOOZ NEED THE BILLING ADDRESS? "
          >
            The billing address is the address you gave to the bank when you
            opened your account with them. It’s the address used to mail your
            bank statements etc.
            <br />
            <br />
            The more information sent to your bank to verify your identity
            allows for faster and smoother approval of the transaction. Less
            Challenges/Failures.
            <br />
            <br />
            This is optional to fill out.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="WHAT IS A CHALLENGE? "
          >
            A request sent by your bank.
            <ul>
              <li>
                It will be an ID that you already know e.g. A password, pin,
                fingerprint (previously saved with your bank){" "}
              </li>
              <br /> OR <br /> <br />
              <li>
                It will be a verification code e.g. One Time Passcode (OTP) that
                you receive from your bank‚ during the transaction. It will be
                sent via email or SMS to the email address or phone number you
                gave to your bank previously.{" "}
              </li>
            </ul>
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="DOES THE CHALLENGE TAKE LONG? "
          >
            No.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="DOES THE CHALLENGE TAKE ME AWAY FROM THE YOOZ PAYMENT PAGE?"
          >
            <ul>
              <li>
                No. Your bank sends the request within the YOOZ payment page.{" "}
              </li>
              <li>
                You simply answer the Challenge (following your bank’s
                instructions) within the YOOZ payment page.{" "}
              </li>
              <li>
                If you need to access your email for the OTP code (sent by your
                bank), you will have to open it in a new browser tab.{" "}
              </li>
              <li>
                If you “go back” or leave the YOOZ payment page to access your
                email in the same browser, the transaction will end, and you
                will have to restart.{" "}
              </li>
            </ul>
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="MY TRANSACTION FAILED.  WHY? "
          >
            A failed transaction can occur for a few reasons.
            <br />
            <br />
            Some may be within your control and can be quickly resolved:
            <ul>
              <li>
                Poor or lost internet service while attempting to make the
                payment{" "}
              </li>
              <li>
                Accidentally leaving the payment page while the transaction was
                processing{" "}
              </li>
              <li>
                Incorrect card details were entered, such as Card Number, Expiry
                Date, CVV, as well as Billing Address.{" "}
              </li>
            </ul>
            There are some reasons for which you may need to contact your bank
            to rectify:
            <ul>
              <li>Expired credit card </li>
              <li>Insufficient funds or funds on hold </li>
              <li>
                Failed authentication of cardholder identity –{" "}
                <i>
                  you may need to provide your bank with updated contact
                  details.
                </i>
              </li>
              <li>
                Your bank declined the transaction for a reason unknown to us.
              </li>
              <li>
                Your Bank card is not 3D Secure enabled -{" "}
                <i>
                  Not all bank cards are equipped to transact on sites that have
                  the latest safety protocols in place. Ask your bank for a 3D
                  Secure 2 enabled card so you can transact safely online.{" "}
                </i>
              </li>
            </ul>
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="MY TRANSACTION FAILED.  WHAT SHOULD I DO?"
          >
            Double check the information entered on payment page to ensure all
            is correct and try again.
            <br />
            <br />
            Or call your bank to:
            <ul>
              <li>enquire on why the transaction failed </li>
              <li>provide your bank with updated contact details </li>
              <li>verify the status of the card </li>
              <li>
                ask your bank for a 3D Secure 2 enabled card so you can transact
                safely online.{" "}
              </li>
            </ul>
            <video controls className={classes.video}>
              <source src={`${API_ROOT}document/yo-failed-txn-vid`} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="MY TRANSACTION KEEPS FAILING.  WHY IS THIS HAPPENING?"
          >
            Frequent failures can mean your bank card is not 3D Secure enabled
            OR your bank placed a “hold” on your card.
            <br />
            <br />
            The “hold” is sometimes applied when you attempt to do online
            transactions. It prevents transactions from being processed, leading
            to a failed transaction.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="WHAT SHOULD I DO WHEN MY TRANSACTION KEEPS FAILING? "
          >
            Call your bank to:
            <ul>
              <li>
                Find out if your card is 3D Secure 2 enabled. If not‚ request a
                3DSecure2 enabled card.{" "}
              </li>
              <li>
                Find out if there is a “hold” on your card. Advise them‚ you are
                attempting to make a transaction using YOOZ and request they
                remove the “hold”.{" "}
              </li>
              <li>
                During this call, your bank may request further information from
                you that will help make the processing of online transactions
                faster with limited/no interruptions in the future e.g. confirm
                your phone number.{" "}
              </li>
            </ul>
            Once the “hold” is removed you can then retry the transaction.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="HOW DOES THIS BENEFIT ME?  "
          >
            Better protection for you. It safeguards your data. Prevents fraud
            and detects risk in real time.
            <br />
            <br />
            The more accurate information submitted leads to more approvals and
            faster transactions.
            <br />
            <br />
            Feel confident knowing your transactions on YOOZ are always safe.
          </YoozAccordion>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="WHAT SERVICES CAN I ACCESS ON YOOZ for Business?  "
          >
            Any one or all of the following. The best part is, there are no
            sign-up or monthly fees! You pay ONLY when you get paid!
            <br />
            <br />
            <b>YOOZ Online</b>
            <br />
            <br />
            Collect payments quickly, easily and securely on our YOOZ Online
            payment platform.
            <br />
            <br />
            <b>YOOZ Scan2Pay </b>
            <br />
            <br />
            Accept payments online with a YOOZ QR Code and Payment Link unique to your
            business and avoid the hassle of terminals and online transfers. No
            website, no problem, YOOZ Scan2Pay moves your business online with one click.
            <br />
            <br />
            <b>YOOZ Payment Gateway </b>
            <br />
            <br />
            This secure Payment Gateway equips your website
            or e-commerce ready site to accept card payments on checkout
            without the hassle of compliance and processing requirements.

            <br />
            <br />
            <b>YOOZ Bill Pay Agents </b>
            <br />
            <br />
            Collect payments from your customers via any of our 150+ Authorized
            YOOZ Bill Payment Agents located throughout Trinidad and Tobago.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="HOW DO I SIGN UP FOR YOOZ for Business? "
          >
            To register your business with YOOZ you will be required to fill out
            certain Know Your Customer documents. The KYC document requirements
            vary based on your business structure.
            <br />
            <br />
            <a href="/OnboardingDocuments">Sole Trader</a>
            <br />
            <br />
            <a href="/OnboardingDocuments">Limited Liability</a>
            <br />
            <br />
            <a href="/OnboardingDocuments">Charity/NGO– Link to Docs</a>
            <br />
            <br />
            <a href="/OnboardingDocuments">School/Alumni Association</a>
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="DO I HAVE TO SUBMIT ALL THE KYC DOCUMENTS REQUESTED? "
          >
            Yes. While we understand that this may be onerous, we wish to note
            that these are the legal requirements.
            <br />
            <br />
            There are varying KYC document requirements based on the business
            structure.
            <br />
            <br />
            Due diligence on business entities is required for the monitoring of
            Anti-Money Laundering (AML) and Combatting the Financing of
            Terrorism (CFT) as per the Financial Obligations Regulations and the
            Central Bank of Trinidad and Tobago (CBTT) AML/CFT Guidelines.
            <br />
            <br />
            As part of YOOZ’s procedure to meet these mandatory CBTT/FIU
            requirements for AML/CFT, all authorized signatories, directors and
            beneficial owners are required to provide two forms of
            identification and proof of residential address.
            <br />
            <br />
            In terms of gathering information related to the directors,
            beneficial owners and authorised signatories, this information can
            be provided by the Company Secretary, or any person authorized by
            the company to do so.
            <br />
            <br />
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="HOW DO I KNOW WHEN CUSTOMERS PAY ME? "
          >
            Payment notifications vary depending on the Payment Channel.
            <br />
            <br />
            As a Bill Payment merchant that uses our YOOZ Online and YOOZ Agents
            services, you are provided with transaction reports of all payments
            made to you by your customers. You can be informed of these
            transactions as follows:
            <ul>
              <li>
                FTP – File Transfer Protocol – periodically based on agreed
                delivery timeline.
              </li>
              <li>
                Website API Integration – payments are automatically applied in
                real time, directly to your customers’ account and invoices.
              </li>
            </ul>
            Merchants with <b>YOOZ</b> Payment Gateway API Integration get
            emails confirming receipt of each payment.
            <br />
            <br />
            <b>YOOZ</b> Scan2Pay merchants will receive confirmation of
            successful payment via an emailed receipt. This receipt can be used
            to reconcile. You can also encourage your customers to send you a
            screen shot of their receipt. We presently do not provide
            transaction reports for this service.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="AS A YOOZ SCAN2PAY MERCHANT HOW DO I KNOW MY CUSTOMER’S PAYMENT WAS SUCCESSFUL? "
          >
            <strong>
              <h3> SUCCESSFUL TRANSACTION</h3>
            </strong>
            <ul>
              <li>
                When a transaction is successful, e-mails are sent
                simultaneously to both you the merchant and the paying customer
                confirming you got paid and the transaction was successful.
              </li>
              <br />
              <li>
                You will receive an email from YOOZ with the header{" "}
                <b>“You Got Paid”</b>.
              </li>
              <br />
              <li>
                All successful transactions will have a transaction number and
                an authorization number.
              </li>
            </ul>
            <br />
            <strong>
              <h3> UNSUCCESSFUL TRANSACTION</h3>
            </strong>
            <ul>
              <li>
                When a transaction is unsuccessful, the customer will recieve an
                email with the header{" "}
                <b>
                  “Your attempted transaction was unsuccessful.”.
                </b>
              </li>
              <br />
              <li>The authorization number will be blank.</li>
              <br />
              <li>
                You (the merchant) will{" "}
                <b>
                  <u>not</u>
                </b>{" "}
                receive any e-mail receipts from YOOZ.
              </li>
              <br />
              <li>
                If the transaction is unsuccessful you can ask your customer to
                try again.
              </li>
            </ul>
            <br />
            <b>
              Goods/services should only be delivered when you receive the
              e-mail receipt with “You Got Paid” header and the authorization
              number included.
            </b>
            <br />
            <br />
            <Grid container spacing={3}>
              <Grid item md={6} sm={12} style={{ textAlign: "center" }}>
                <h3>MERCHANT successful transaction email sent to you</h3>
                <img
                  src={scan2payemail}
                  alt="Description for image 1"
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item md={6} sm={12} style={{ textAlign: "center" }}>
                <h3>CUSTOMER successful transaction e-mail receipt</h3>
                <img
                  src={scan2payreciept}
                  alt="Description for image 2"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="HOW LONG DO TRANSACTIONS TAKE TO PROCESS? "
          >
            <b>YOOZ</b> processes transactions in seconds.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="HOW DOES MY BUSINESS RECEIVE NOTIFICATIONS OF SUCCESSFUL TRANSACTIONS? "
          >
            API: Real time. <br />
            FTP: Daily. Can be done as frequently as every 5 minutes. <br />
            Email: Scan2Pay Merchants receive e-mail confirmation on each
            successful transaction. <br />
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="HOW LONG DOES IT TAKE TO RECEIVE SETTLEMENTS FROM YOOZ? "
          >
            3 to 5 business days.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="IF I DON’T HAVE A WEBSITE, CAN I STILL REGISTER MY BUSINESS WITH YOOZ? "
          >
            Yes!
          </YoozAccordion>
        </TabPanel>
        <TabPanel value={value} index={6}>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="WHAT IS A YOOZ AGENT?  "
          >
            A business place that sells retail goods and services to customers.
            Working on behalf of YOOZ, they sell Digicel and Bmobile Top Up and
            / or collect Bill Payments for major utilities.
            {/* <Button
              component={"a"}
              href={registrationPackage}
              color="primary"
              size="small"
              variant="contained"
            >
              Download Registration Package
            </Button> */}
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="WHY BECOME A YOOZ AGENT?    "
          >
            You can earn additional revenue! Earn up to $5.00 per bill pay
            transaction and commissions on Top Up. Selling Top Up and Collecting
            Bill Payments will also increase foot traffic through your stores.
            <br />
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="WANT TO SELL TOP UP? MAKE SURE YOU’RE READY WITH THE FOLLOWING: "
          >
            <ul>
              <li>Physical Business Location </li>
              <li>Internet access at the location </li>
              <br />
              Optional
              <br />
              <br />
              <li>
                Functional Point of Sale (POS), laptop or desktop system, for
                those that prefer to sell off their own devices.{" "}
              </li>
              <li>
                Any Android device, for customers interested in our Direct Top
                Up (DTU) service.{" "}
              </li>
            </ul>
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="WANT TO COLLECT BILL PAYMENTS? MAKE SURE YOU’RE READY WITH THE FOLLOWING:"
          >
            <ol>
              <li>A physical location - Brick & Mortar Store</li>
              <li>Internet access at the location</li>
              <li>A computer device (Laptop, Desktop, Point of Sale device)</li>
              <li>A thermal receipt printer</li>
            </ol>
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="HOW DO I BECOME A YOOZ AGENT?  "
          >
            Contact us via email or call to set up an appointment with one of
            our Agent Support Staff.
            <br />
            To register you will need to provide:
            <ul>
              <li>A National ID </li>
              <li>Proof of Address - Utility Bill in your name </li>
              <li>
                Business Registration Certificate (if business is registered){" "}
              </li>
            </ul>
            Once you are approved to become an Authorized YOOZ Agent, we will
            provide an Agent Agreement contract which must be signed before
            activating your YOOZ Agent status.
          </YoozAccordion>
          <YoozAccordion
            id={"accordionID" + accCount++}
            summary="WILL I GET TRANSACTION REPORTS? "
          >
            Yes! Our web-based application gives YOOZ Agents access to a variety
            of transaction reports that can be generated any time from any
            internet ready device.
          </YoozAccordion>
          <YoozAccordion
              id={"accordionID" + accCount++}
              summary="HOW WILL CUSTOMERS KNOW THEY CAN TOP UP OR PAY BILLS AT MY STORE?  "
          >
            We will provide YOOZ branding which can be installed at your store.
            <br/>
            <br/>
            On our website we provide both an Agent listing of
            our Bill Pay Agents as well as a Google map so customers can
            search for an Agent in their preferred area.
            <br/>
            Every month we promote Agents
            on our social media pages.
          </YoozAccordion>
        </TabPanel>
      </Paper>
    </Container>
  );
}
