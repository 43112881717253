import { Button, Container, useMediaQuery } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import ContentList from "components/public-dashboard/components/contentList";
import { setTabPosition } from "components/public-dashboard/uiSlice";
import {API_ROOT} from "constants/actionTypes";


const useStyles = makeStyles((theme) => ({
  img: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  yoozBlueLink: {
    color:"#00AFE2"
  },
}));

export default function TopupContent() {
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const dispatch = useDispatch();
  const LinkText = (props) => {
    const { navIndex, route, text } = props;
    return (
      <Link
        onClick={() => {
          window.scrollTo(0, 0);
          dispatch(setTabPosition(navIndex));
        }}
        component={RouterLink}
        to={route}
        // color="primary"
        className={classes.linktext}
      >
        <b>{text}</b>
      </Link>
    );
  };

  return (
    <Container className={classes.root}>
      <ContentList
        title="QUICK PAY"
        items={[
          "FREE to use, all you need is your account info and your bank card! No need to register... just pay!",
          <Button
            component={RouterLink}
            to="/quickpay"
            color="primary"
            size="large"
            variant="contained"
            onClick={() => {
              window.scrollTo(0, 0);
              dispatch(setTabPosition(7));
            }}
            style={{ borderRadius: 20, marginRight: 10 }}
          >
            Click Here
          </Button>,
        ]}
      />
      <ContentList
        title="LOGIN"
        items={[
            <span>
            Keep track of all your payments and <b>YOOZ It</b> for FREE, anywhere, anytime
            you want! Don't have a Login Account? <a href="signup"
                                                     className={classes.yoozBlueLink}>Sign up</a> for FREE.
          </span>,
            <Button
                component={RouterLink}
                to="/login"
            color="primary"
            size="large"
            variant="contained"
            onClick={() => {
              window.scrollTo(0, 0);
              dispatch(setTabPosition(8));
            }}
            style={{ borderRadius: 20, marginRight: 10 }}
          >
            Click Here
          </Button>,
        ]}
      />
      <ContentList
        title="YOOZ Scan2Pay"
        items={[
          
          <span>Pay for goods and services with <span style={{color:"#cb010d",fontWeight:"bold"}}>YOOZ Scan2Pay</span>.  It’s FREE and no sign up necessary.  Ask for the <b> YOOZ QR Code</b> or <b>YOOZ Payment Link</b> from your favourite business places to pay the safe, easy and contactless way.</span>,
          
        ]}
      />
       <ContentList
        title=""
        items={[
            <video width={xs ? "100%" : 500} height={xs ? 400 : 500} controls>
                <source src={`${API_ROOT}document/yo-scan2pay-vid`} type="video/mp4"/>
                Your browser does not support the video tag.
            </video>,
        ]}
       />
        <ContentList
            title="YOOZ Agent Network"
            items={[

                <span>Prefer to pay your bills in cash, without the hassle of long lines and travelling long distances?
          Enjoy the convenience of paying your bills at an easily accessible <a href="AgentLocations" className={classes.yoozBlueLink}>location </a>
           within your very own community. 
           <br/> <br/>
           Look for the YOOZ sign at your nearby shop, supermarket or pharmacy.
           <br/> <br/>
           It’s quick and easy!    
           </span>,
          
        ]}
      />
    </Container>
    
  );
}
