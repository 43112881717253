import React from "react";

export default function YoozSvgIcon(props) {
  const { height, width, color, minWidth = 10, maxWidth = "100%" } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 322 128"
      style={{ paddingLeft: 5, paddingRight: 5, minWidth, maxWidth }}
    >
      <g
        id="Group_18"
        data-name="Group 18"
        transform="translate(-669.874 -617.217)"
        fill={color}
      >
        <path
          id="Path_41"
          data-name="Path 41"
          d="M991.874,697.529a8.513,8.513,0,1,0-8.512,8.44A8.478,8.478,0,0,0,991.874,697.529Z"
        />
        <g id="Group_17" data-name="Group 17">
          <path
            id="Path_42"
            data-name="Path 42"
            d="M717.589,642.264a214.213,214.213,0,0,1,4.4,28.754s1.229,11.008,1.229,12.068h.3s16.026-25.977,16.026-31.481a7.055,7.055,0,0,0-.423-1.993,11.378,11.378,0,0,1-1.013-4.718,11.286,11.286,0,0,1,22.571,0l-.038.9c-.667,18.618-28.476,54.914-44.279,73.9-10.362,12.232-22.515,25.212-37.073,25.52l-.688-.051a9.393,9.393,0,0,1,.741-18.762,9.778,9.778,0,0,1,3.213.548l.381.156a3.477,3.477,0,0,0,1.371.233c6.168,0,19.652-18.583,19.8-18.891l.925-1.222-2-16.045c-1.69-13.3-5.851-36.671-7.854-40.95-1.365-2.577-.152-.232-.152-.232a11.851,11.851,0,0,1-1.41-5.633,12.085,12.085,0,0,1,23.9-2.417Z"
          />
          <path
            id="Path_43"
            data-name="Path 43"
            d="M802.327,630.98c27.266,0,31.582,19.559,31.582,28.424a59.929,59.929,0,0,1-12.79,35.907c-5.239,6.423-13.863,13.138-30.195,13.138-30.961,0-31.275-27.2-31.275-30.413C759.65,663.375,767.975,630.98,802.327,630.98Zm-10.02,63.111c15.413,0,19.725-27.2,19.725-35.765,0-10.229-6.32-12.984-10.786-12.984-14.334,0-20.033,23.379-20.033,35.3C781.214,687.67,784.759,694.091,792.308,694.091Z"
          />
          <path
            id="Path_44"
            data-name="Path 44"
            d="M878.262,630.98c27.268,0,31.587,19.559,31.587,28.424a59.846,59.846,0,0,1-12.79,35.907c-5.236,6.423-13.859,13.138-30.2,13.138-30.969,0-31.275-27.2-31.275-30.413C835.588,663.375,843.911,630.98,878.262,630.98Zm-10.015,63.111c15.4,0,19.722-27.2,19.722-35.765,0-10.229-6.313-12.984-10.783-12.984-14.331,0-20.026,23.379-20.026,35.3C857.16,687.67,860.7,694.091,868.247,694.091Z"
          />
          <path
            id="Path_45"
            data-name="Path 45"
            d="M965.056,633.7l-.643.03-8.565,0,1.223-5.153c.037-.143.106-.275.138-.419s.031-.3.059-.444l.059-.219-.026-.008a8.94,8.94,0,0,0-17.362-4.033l-.062-.019-.13.581a.16.16,0,0,0-.03.071.6.6,0,0,0-.016.076l-2.25,9.567-13.043-.008-1.219.022a8.729,8.729,0,0,0-8.774,8.775,8.9,8.9,0,0,0,13.356,7.61l.432-.263c1.175-.735,2.818-1,5.353-1H946.81L904.9,690.7l-.518.455a8.574,8.574,0,0,0-2.561,6.126,8.683,8.683,0,0,0,8.723,8.645l2.079-.073h7.617l-1.131,4.788c0,.033-.026.059-.026.094s-.011.06-.021.087l-.275,1.159.115.024a8.952,8.952,0,0,0,17.25,4.007l.094.029.194-.841c.039-.136.1-.259.131-.4a2.969,2.969,0,0,0,.053-.411l2.026-8.538h15.481l.688.021a8.73,8.73,0,1,0-6.184-15.442l-.362.129c-1.37.624-5.308.624-6.555.624H928.009L971.182,649.1a8.682,8.682,0,0,0,1.82-2.931l.016-.059.043-.227a9,9,0,0,0,.565-3.149A8.829,8.829,0,0,0,965.056,633.7Z"
          />
        </g>
      </g>
    </svg>
  );
}
