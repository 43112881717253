import {
  Divider,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { Fragment } from "react";
import yoozIt_img from "assets/images/home_content_1.png";
import topUp_img from "assets/images/home_content_2.png";
import billPay_img from "assets/images/home_content_3.png";
import getpPaid_img from "assets/images/home_content_4.png";
import signUp_img from "assets/images/home_content_6.png";
import yoozIt_logo from "assets/images/yoozit.png";
import digicel from "assets/images/provider_3.png";
import bmobile from "assets/images/provider_ (2).png";
import TextList from "components/public-dashboard/components/TextList";
import HomeHeader from "./header";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setTabPosition } from "components/public-dashboard/uiSlice";
import IconHeader from "components/public-dashboard/components/iconHeader";

const useStyles = makeStyles((theme) => ({
  headingTitle: {
    fontFamily: "Akzidenz-Grotesk BQ, serif",
  },
  content: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.up("lg")]: {
      marginLeft: "5%",
    },
  },

  negativeMargin: {
    marginTop: -12,
  },
  signUpContainer: {
    background: "#F2F2F2",
    margin: "auto",
    paddingTop: 20,
  },
  yoozBlueLink: {
    color: "#00AFE2",
  },
  root: {
    margin: "auto",
    marginTop: 20,
    marginBottom: 20,
    // textAlign: "center",
  },
  link: {
    color: theme.palette.secondary.main,
    fontFamily: "Akzidenz-Grotesk BQ, serif",
  },
  img: {
    width: "100%",
  },
  linktext: {
    color: theme.palette.primary.light,
    "&:hover": { color: `${theme.palette.primary.main} !important` },
  },
}));

export default function ContentSection() {
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();

  const LinkText = (props) => {
    const { navIndex, route, text } = props;
    return (
      <Link
        onClick={() => {
          window.scrollTo(0, 0);
          dispatch(setTabPosition(navIndex));
        }}
        component={RouterLink}
        to={route ?? ""}
        // color="primary"
        className={classes.linktext}
      >
        <b>{text}</b>
      </Link>
    );
  };

  const NoLinkText = (props) => {
    const { navIndex, route, text } = props;
    return (
      <span
        // color="primary"
        className={classes.linktext}
      >
        <b>{text}</b>
      </span>
    );
  };
  return (
    <Fragment>
      <div className={classes.root}>
        <HomeHeader
          path="/quickpay"
          tabPosition={0}
          special={
            <IconHeader
              titleLg="h1"
              titleSm="h1"
              titleXs="h2"
              iconColor="#BD2126"
              titleColor="#BD2126"
              yoozIt={true}
              styles={{ alignItems: "end" }}
            />
          }
          list={
            <TextList
              items={[
                <span>
                  <b>YOOZ</b>&nbsp;It to pay bills!
                  <br />
                  <b>YOOZ</b>&nbsp;It to top up!
                  <br />
                  <b>YOOZ</b>&nbsp;It to get paid!
                  <br />
                </span>,
                <span>
                  <b>ANYWHERE, ANYTIME</b>&nbsp; <br />
                </span>,
                <span>
                  It's convenient, safe and easy! <br />
                  No lines! No problems!
                </span>,
              ]}
            />
          }
          img={yoozIt_img}
        />
        {xs && <Divider variant="middle" />}

        <HomeHeader
          tabPosition={1}
          title="PAY BILLS"
          path="/billpay"
          color="#57B56B"
          titleSm="h2"
          titleLg="h1"
          titleXs="h3"
          imgPosition="left"
          list={
            <TextList
              items={[
                <span>
                  Pay major utilities or purchase goods and services wherever
                  you are.
                </span>,
                <span>
                  <NoLinkText text="YOOZ Online" /> <br />
                  <b> Payment Method – Credit Cards & Visa Debit Cards </b>{" "}
                  <br />
                  <b>
                    <i>FREE SERVICE</i>
                  </b>
                </span>,
                <ul>
                  <li>
                    <span>
                      <LinkText
                        navIndex={7}
                        route="/quickpay"
                        text="Quick Pay"
                      />{" "}
                      <br />
                      No need to sign up... just pay!
                    </span>
                  </li>
                  <li>
                    <span>
                      <LinkText navIndex={7} route="/login" text="Login" />{" "}
                      <br />
                      <a
                        href="/signup"
                        target="_blank"
                        className={classes.yoozBlueLink}
                      >
                        Sign up
                      </a>{" "}
                      for FREE. Keep track of all your payments and account
                      info, making payments faster and easier.
                    </span>
                  </li>
                </ul>,
                <span>

                  <LinkText
                      navIndex={2}
                      route="/scan2payGnS"
                      text="YOOZ Scan2Pay"
                  />{" "}
                  <br/>
                  The Safe, Easy, Contactless Way to PAY for your favourite
                      goods and services, instore (QR Code) or wherever you are
                      (Payment Link).
                </span>,
                <span>
                  <LinkText
                      navIndex={8}
                      route="/AgentLocations"
                      text="YOOZ Bill Pay Agent Network"
                  />{" "}
                  <br/>
                  <b>Payment Method – Cash or Debit Card</b>
                  <br/>
                  Visit a{" "}
                  <a
                      href="/AgentLocations"
                      className={classes.yoozBlueLink}
                  >
                    location{" "}
                  </a>{" "}
                  near you and pay your bill with cash or debit card.
                </span>,
              ]}
            />
          }
          img={billPay_img}
        />
        {xs && <Divider variant="middle" />}
        <HomeHeader
          tabPosition={2}
          title="Top Up"
          path="/topup"
          color="#3A75BA"
          titleSm="h2"
          titleLg="h1"
          titleXs="h3"
          imgPosition="right"
          list={
            <TextList
              items={[
                <span>
                  Top Up any <strong>B-Mobile</strong> or{" "}
                  <strong>Digicel</strong> Prepaid phone, yours or someone
                  else’s…anywhere, anytime…at NO EXTRA COST.
                </span>,
                <span>
                  <NoLinkText text="YOOZ Online" /> <br />

                  <b> Payment Method – Credit Cards & Visa Debit Cards </b>{" "}
                  <br />
                  <b>
                    <i>FREE SERVICE</i>
                  </b>
                </span>,
                <ul>
                  <li>
                    <span>
                      <LinkText
                        navIndex={7}
                        route="/quicktopup"
                        text="Quick Top Up"
                      />{" "}
                      <br />
                      No need to sign up... just top up!
                    </span>
                  </li>
                  <li>
                    <span>
                      <LinkText navIndex={7} route="/login" text="Login" />{" "}
                      <br />
                      <a
                        href="/signup"
                        target="_blank"
                        className={classes.yoozBlueLink}
                      >
                        Sign up
                      </a>{" "}
                      for FREE. Keep track of all your top ups, making payments
                      faster and easier.
                    </span>
                  </li>
                </ul>,
                <span>
                  <NoLinkText text="YOOZ Agent Network" /> <br />
                  <b>Payment Method – Cash or Debit Card</b>
                  <br />
                  Visit a location near you and top up. Look out for the YOOZ
                  Authorised Agent sticker at a shop, supermarket or pharmacy
                  within your community.
                </span>,
              ]}
            />
          }
          img={topUp_img}
        />
        {xs && <Divider variant="middle" />}

        <HomeHeader
          tabPosition={3}
          path="/partners"
          title="Get Paid"
          color="#F7517B"
          titleSm="h2"
          titleLg="h1"
          titleXs="h3"
          imgPosition="left"
          list={
            <TextList
              items={[
                <span>
                  Looking for safe, convenient, affordable ways for your
                  customers and clients to pay? If the answer is YES, the answer
                  is YOOZ.
                </span>,
                <span>
                  Expand your earning potential and connect with your customers
                  and partners wherever they are.
                </span>,
                <span>
                  Cash or card, debit or credit, Agent network or Online, our
                  extensive payment channel offering, caters to the needs and
                  means of your customers. View all the ways you can{" "}
                  <a href="/partners" className={classes.yoozBlueLink}>
                    {" "}
                    Collect Payments{" "}
                  </a>
                  here.
                </span>,
              ]}
            />
          }
          img={getpPaid_img}
        />
      </div>
      <div className={classes.signUpContainer}>
        <HomeHeader
          path="/partners"
          title={"YOOZ PARTNERS"}
          color={theme.palette.secondary.main}
          titleSm="h2"
          titleLg="h1"
          titleXs="h2"
          list={<TextList items={[]} />}
          img={signUp_img}
        />
      </div>
    </Fragment>
  );
}
