import { Container, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import React from "react";
import IconHeader from "components/public-dashboard/components/iconHeader";
import TextList from "components/public-dashboard/components/TextList";

export default function AboutusHeader() {
  const theme = useTheme();

  return (
    <Container>
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <Grid item>
          <IconHeader
            title="About Us"
            iconColor={theme.palette.primary.main}
            titleColor="#709C07"
            titleLg="h2"
          />
        </Grid>
        {/* <b><b>YOOZ</b>{" "} </b>{" "} 
        <a style={{ color: "#1cc5ff" }}>
        */}
        <Grid item>
          <TextList
            items={[
              <span>
                 Founded in 2005, the service that is now known as <b>YOOZ</b>{" "}  was conceptualised by a group of innovators who realised that there was a growing need for alternative payment solutions in Trinidad & Tobago and the wider Caribbean.
              </span>,
              <span>
                Originally called Easy Top Up, <b>YOOZ</b>{" "}  grew to include bill payments and a variety of e- commerce solutions that service the telecommunications, utilities, entertainment, distribution and retail industries, while supporting and encouraging small business and entrepreneurship.
              </span>,
              <span>
                Today, <b>YOOZ</b>{" "} is one of <a style={{ color: "#1cc5ff", fontWeight:"bold" }}>the most comprehensive Digital Payment Facilitators</a> operating with a uniquely dynamic and flexible core payment platform.
              </span>,
              <span>
                Our goal is to <a style={{ color: "#1cc5ff", fontWeight:"bold" }}>simplify payment services</a> with innovative solutions that facilitate fast, flexible, easy and secure transactions.  We are committed to safeguarding your data and transactions by employing best practices, regulatory and industry standard-compliant auditable processes‚ encryption and tokenisation technology.
              </span>,
              <span>
                Inspired by our culture of inclusion, equal opportunity and embracing change our team develops and fine tunes solutions that create opportunities for both the banked and unbanked to safely, quickly and easily, send and receive payments.
              </span>,
              <span>
                We believe <a style={{ color: "#1cc5ff", fontWeight:"bold" }}>everyone in all sectors of society, deserves a level playing field</a> to transact legitimate business through <a style={{ color: "#1cc5ff", fontWeight:"bold" }}>multiple payment channels</a> that suit their varying needs.
              </span>,
            ]}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
