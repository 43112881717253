import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_ROOT } from "constants/actionTypes";
import { getAsync } from "components/public-dashboard/services/api_util";
import { setAnonTokenApi } from "components/public-dashboard/services/utility";
import { getUserDetail } from "middleware/storage";
export const fetchProviders = createAsyncThunk(
  "billpay/fetchProviders",
  async (thunkAPI) => {
    await setAnonTokenApi();
    const response = await getAsync(`${API_ROOT}Transaction/GetProviders`);
    if (response.state === 1) {
      return response.data;
    }
    return [];
  }
);

export const fetchCharities = createAsyncThunk(
  "billpay/fetchCharities",
  async (thunkAPI) => {
    await setAnonTokenApi();
    const response = await getAsync(`${API_ROOT}Transaction/GetCharities`);
    if (response.state === 1) {
      return response.data;
    }
    return [];
  }
);

export const fetchCC = createAsyncThunk("billpay/fetchCC", async (thunkAPI) => {
  const response = await getAsync(`${API_ROOT}customers/getcardlist`);
  if (response.state === 1) {
    return response.data;
  }

  return [];
});

export const fetchCountries = createAsyncThunk(
  "billpay/fetchCountries",
  async (thunkAPI) => {
    await setAnonTokenApi();
    const response = await getAsync(`${API_ROOT}public/countries`);
    if (response.state === 1) {
      return response.data;
    }
    return [];
  }
);

export const fetchStates = createAsyncThunk(
  "billpay/fetchStates",
  async (countryCode, thunkAPI) => {
    await setAnonTokenApi();
    const response = await getAsync(`${API_ROOT}public/country/${countryCode}/states`);
    if (response.state === 1) {
      return response.data;
    }
    return [];
  }
);


const user = getUserDetail();

const cleanState = {
  paymentDetails: {
    name: "",
    cardNumber: "",
    expirationDate: "",
    cvv: "",
    customerCardId: 0,
    shouldSaveCard: false
  },
  addressDetails: {
    firstName: "",
    lastName: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    postalCode: "",
    countryCode: "780",
    emailAddress: "",
    phoneNumber: "1868"
  },
  txnDetails: {
    amount: 0,
    accountNumber: "",
    providerId: "",
    charityId: "",
    transactionType: 1,
    email: "",
    donationAmount: "",
    comment: "",
    phoneNumber: "1868",
    accountHolderName:""
  },
  submissionDetails: {
    tac: false,
    ppolicy: false,
    saveCard: false,
  },
  errors: {
    amount: "",
    donationAmount: "",
    providerId: "",
    charityId: "",
    accountNumber: "",
    name: "",
    cardNumber: "",
    expirationDate: "",
    cvv: "",
    email: "",
    comment: "",
    customerCardId: "",
    agreement: "",
    phoneNumber: ""
  },
  providers: [],
  charities: [],
  cCards: [],
  countries: [],
  states: [],
  loading: false,
  showCCForm: false,
};

const cleanAddress = {
  firstName: "",
  lastName: "",
  line1: "",
  line2: "",
  city: "",
  state: "",
  postalCode: "",
  countryCode: "780",
  emailAddress: "",
  phoneNumber: "1868"
}

// const testingState = {
//   paymentDetails: {
//     name: "Haifeng Mei",
//     cardNumber: "4111111111111111",
//     expirationDate: "1233",
//     cvv: "4444",
//     customerCardId: 0,
//   },
//   txnDetails: {
//     amount: "222.44",
//     accountNumber: "1234553",
//     providerId: 5,
//     transactionType: 1,
//     email: "hmei@reseonancecaribbean.com",
//   },
//   submissionDetails: {
//     tac: true,
//     ppolicy: true,
//   },
//   errors: {
//     amount: "",
//     providerId: "",
//     accountNumber: "",
//     name: "",
//     cardNumber: "",
//     expirationDate: "",
//     cvv: "",
//     email: "",
//     customerCardId: "",
//     agreement: "",
//   },
//   providers: [],
//   cCards: [],
//   loading: false,
//   showCCForm: true,
// };

const bpSlice = createSlice({
  name: "billpay",
  initialState: cleanState,
  reducers: {
    setTxnDetails(state, action) {
      state.txnDetails = action.payload;
    },
    setPaymentDetails(state, action) {
      state.paymentDetails = action.payload;
    },
    setAddressDetails(state, action) {
      state.addressDetails = action.payload;
    },
    setSubmissionDetails(state, action) {
      state.submissionDetails = action.payload;
    },
    setErrors(state, action) {
      state.errors = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    toggleCCForm(state, action) {
      state.showCCForm = action.payload;
    },
    resetTxn(state, action) {
      state.txnDetails = { ...cleanState.txnDetails };
      state.paymentDetails = { ...cleanState.paymentDetails };
      state.addressDetails = { ...cleanState.addressDetails };
      state.submissionDetails = { ...cleanState.submissionDetails };
      state.errors = { ...cleanState.errors };
    },
    resetBilling(state) {
      state.addressDetails = {
        ...cleanAddress
      }
    }
  },
  extraReducers: {
    [fetchProviders.fulfilled]: (state, action) => {
      state.providers = [...action.payload];
    },
    [fetchCharities.fulfilled]: (state, action) => {
      state.charities = [...action.payload];
    },
    [fetchCC.fulfilled]: (state, action) => {
      state.cCards = [...action.payload];
    },
    [fetchCountries.fulfilled]: (state, action) => {
      state.countries = [...action.payload];
    },
    [fetchStates.fulfilled]: (state, action) => {
      state.states = [...action.payload];
    }
  },
});

export const {
  setTxnDetails,
  setPaymentDetails,
  setAddressDetails,
  setSubmissionDetails,
  setErrors,
  setLoading,
  toggleCCForm,
  resetTxn,
  resetBilling
} = bpSlice.actions;

export default bpSlice.reducer;
