import { FormHelperText, IconButton, TextField, Typography, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import WarningIcon from "@material-ui/icons/ReportProblemOutlined";
import NumberFormat from "react-number-format";
import { amber } from "@material-ui/core/colors";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  margin: {
    marginBottom: theme.spacing(1),
  },
  disclaimer: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      fixedDecimalScale
      decimalScale={2}
      thousandSeparator
      isNumericString
      prefix="(TTD) $"
    />
  );
}

export default function EnterAmount(props) {
  const classes = useStyles();
  const theme = useTheme(); // Access theme using useTheme hook

  const { value, handleChange, error, disabled, elemId, label, fieldName, helperText } = props;
  const handleFocus = (event) => event.target.select();

  return (
    <div className={classes.root}>
      <TextField
        id={elemId}
        value={value}
        label={label}
        onFocus={handleFocus}
        onChange={handleChange(fieldName)}
        fullWidth
        variant="outlined"
        error={error.length > 0}
        helperText={error.length > 0 ? error : helperText}
        InputProps={{
          inputComponent: NumberFormatCustom,
        }}
        color="secondary"
        disabled={disabled}
      />
    </div>
  );
}